/* Reset and Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Source Serif Pro', serif;
  line-height: 1.6;
  background-color: #f9f9f9;
}

/* Navbar Styling */
/* .navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #ddd;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  height: 70px;
}

.navbar img {
  height: 50px;
}

.navbar div {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.navbar a {
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: bold;
  white-space: nowrap;
} */

/* General Navbar Styling */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #ddd;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.navbar-logo {
  height: 50px;
}

.navbar-links {
  display: flex;
  gap: 20px;
  margin-left: auto; /* Push links to the right */
}

.navbar-links a {
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: bold;
  white-space: nowrap;
}

/* Hide the links initially for mobile */
@media (max-width: 768px) {
  .navbar-links {
    display: none; /* Initially hidden */
    flex-direction: column;
    position: absolute;
    top: 70px; /* Below the navbar */
    right: 0;
    background-color: #fff;
    width: 100%;
    text-align: center;
    padding: 20px 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .navbar-links.active {
    display: flex; /* Show the links when active */
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: 5px;
  }

  .bar {
    height: 3px;
    width: 25px;
    background-color: black;
  }
}

/* Desktop Styling */
@media (min-width: 769px) {
  .hamburger {
    display: none; /* Hide the hamburger menu on desktop */
  }

  .navbar-links {
    display: flex; /* Ensure links are visible on desktop */
  }
}

/* Section Styling */
.section {
  height: 600px; /* Fixed section height */
  padding: 40px 10%; /* Consistent padding */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

/* Hero Section */
.hero {
  background: linear-gradient(to bottom, white, #f3f3f3);
  padding: 50px 10%; /* Adjust padding */
}

.hero h1 {
  font-size: 4rem;
  margin-bottom: 20px;
}

.hero button {
  background-color: black;
  color: white;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 50px;
  font-size: 1.2rem;
  transition: background-color 0.3s;
}

.hero button:hover {
  background-color: #333;
}

/* About Section */
.about {
  display: flex;
  align-items: stretch;
  height: 600px;
}

.about-content {
  flex: 1;
  padding: 80px;
  text-align: left;
}

.about-content h2 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.about-content p {
  font-size: 1.8rem;
  line-height: 1.3;
  color: #ff5757;
}

.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensure no overflow from the container */
}

.about-image img {
  max-width: 100%; /* Limit the width to the container */
  height: auto; /* Maintain the original aspect ratio */
  object-fit: contain; /* Ensure the image fits within the container without cropping */
}


/* Services Section Styling */
.services {
  background-color: #ff6b6b;
  color: white;
  padding: 60px 10%;
  text-align: center;
}

.services h2 {
  font-size: 3rem;
  margin-bottom: 30px;
}

.services h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

.services p {
  font-size: 1.0rem;
}

/* Container for service items */
.service-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 20px;
}

/* Each service item */
.service-item {
  width: 30%; /* Adjust as needed for layout */
  text-align: center;
}

/* Icon Styling */
.service-item img {
  width: 100px;
  height: 100px;
  margin-bottom: 15px;
  object-fit: contain;
}


/* Testimonials Section */
.testimonials {
  background-color: #fafafa;
  text-align: center;
  padding: 60px 10%;
}

.testimonials h2 {
  font-size: 3rem;
}

.testimonial-list {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.testimonial-item {
  flex: 1;
  min-width: 200px;
  text-align: center;
}

/* Testimonial Image Styling */
.testimonial-item img {
  width: 120px;      /* Fixed size */
  height: 120px;     /* Fixed size */
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image fits without distortion */
  margin-bottom: 15px;
  border: 2px solid #ddd; /* Optional border for styling */
}

/* Closing Section Styling */
.closing {
  background-color: black;
  color: white;
  padding: 80px 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.closing-content {
  display: flex;
  align-items: center;
  max-width: 900px;
  gap: 20px; /* Space between icon and text */
}

.closing-icon {
  -webkit-filter: invert(100%);
  width: 150px;
  height: 150px;
  object-fit: contain; /* Ensure the icon maintains aspect ratio */
}

.closing-content p {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.6;
  margin: 0;
  max-width: 600px; /* Limit text width for readability */
}

/* Contact Section Styling */
.contact-section {
  background-color: white;
  padding: 60px 10%;
  text-align: center;
}

.contact-section h2 {
  color: #ff6b6b; /* Orange heading */
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.contact-details {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
}

.contact-item {
  flex: 1;
  min-width: 250px;
  max-width: 300px;
  text-align: center;
}

.contact-item h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.contact-item p {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
}

/* Footer Styling */
.footer {
  background-color: #ff6b6b; /* Dark purple color */
  color: white;
  text-align: center;
  padding: 15px 0;
  position: relative;
  width: 100%;
  bottom: 0;
}

.footer-content p {
  margin: 0;
  font-size: 1rem;
}

.footer-content a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.footer-content a:hover {
  text-decoration: underline;
}

/* Portal Section Styling */
.portal {
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 10%;
  text-align: center;
  gap: 20px; /* Add spacing between elements */
}

/* Heading Styling */
.portal h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Form Styling */
.portal form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 400px;
  width: 100%;
}

.portal input,
.portal textarea,
.portal select,
.portal button {
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
  font-size: 1rem;
}

.portal button {
  background-color: #ff6b6b;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.portal button:hover {
  background-color: #ff5757;
}

/* Link Styling */
.interest-link {
  margin-top: 10px;
  color: #ff6b6b;
  text-decoration: none;
  font-size: 1rem;
}

.interest-link:hover {
  text-decoration: underline;
}

/* Vision Section */
.vision {
  background-color: #fafafa;
  text-align: center;
  padding: 60px 10%;
}

.vision h2 {
  font-size: 3rem;
}

.vision p {
  font-size: 1.5rem;
}


/* Mission Section */
.mission {
  background-color: #ff6b6b;
  color: white;
}

.mission h2 {
  font-size: 3rem;
  margin-bottom: 30px;
}

.mission p {
  font-size: 1.5rem;
}

.mission-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
}

.mission-item {
  flex: 1;
  min-width: 200px;
  text-align: center;
}



/* Contact Us Section Styling */
.contact-section {
  background-color: white;
  padding: 80px 10%;
  text-align: center;
}

.contact-details {
  display: flex;
  justify-content: space-evenly; /* Equal spacing between contact items */
  align-items: center; /* Center align vertically */
  margin-top: 30px;
  flex-wrap: wrap; /* Ensure proper wrapping on smaller screens */
  gap: 40px; /* Space between rows */
}

.contact-item {
  text-align: center;
  min-width: 250px; /* Ensure consistent width for items */
}

.contact-link {
  text-decoration: none;
  color: #ff6b6b; /* Matching your theme color */
  font-weight: bold;
}

.contact-link:hover {
  text-decoration: underline;
}

/* Social Icons Styling */
.social-links {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.social-icon {
  width: 35px;
  height: 35px;
  transition: transform 0.3s ease-in-out;
}

.social-icon:hover {
  transform: scale(1.1);
}




/* Contact Form Styling */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 0.5px; /* Adjust this value to control input spacing */
  max-width: 400px;
  margin: 0 auto;
}

.contact-form input,
.contact-form textarea {
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-family: 'Arial', sans-serif; /* Consistent font */
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.contact-form textarea {
  resize: none;
  height: 100px;
}

.contact-form button {
  background-color: #ff6b6b;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 10px;
}

.contact-form button:hover {
  background-color: #ff5757; /* Slightly darker on hover */
}

/* Responsive Spacing Fix for Sections */
@media (max-width: 768px) {
  /* Hero Section Spacing */
  .hero {
    margin-top: 70px; /* Adjust to clear the navbar */
    padding-top: 20px;
  }

  .hero h1 {
    margin-bottom: 10px; /* Less spacing */
  }

  /* Section Height Adjustment */
  .section {
    height: auto; /* Allow sections to resize dynamically */
    padding: 40px 5%; /* Reduced padding to prevent overflow */
  }

  /* About Section Adjustments */
  .about {
    height: auto; /* Let the section grow naturally with content */
    padding: 20px 5%; /* Padding adjusted for better fit on mobile */
  }

  .about-content {
    padding: 20px;
  }

  /* Testimonials Section Adjustments */
  .testimonials {
    padding: 20px 5%; /* Less padding to prevent content overlap */
    margin-top: 30px; /* Add a margin to prevent overlap with previous section */
  }

  .testimonial-item {
    margin-bottom: 30px; /* Extra space for better separation */
  }

  /* Services Section */
  .services {
    padding: 30px 5%;
    margin-bottom: 30px; /* Add spacing below to separate sections */
  }

  /* Closing Section Adjustment */
  .closing {
    padding: 30px 5%;
    margin-top: 40px; /* Add margin-top to prevent overlap with previous content */
  }

  .closing-content {
    text-align: center; /* Ensure content is centered on mobile */
  }

  /* Contact Section Adjustment */
  .contact-section {
    margin-top: 50px; /* Ensure enough spacing from previous sections */
    padding: 40px 5%;
  }

  /* Contact Form and Button Adjustment */
  .contact-form input,
  .contact-form textarea {
    font-size: 1rem;
    margin-bottom: 10px; /* Add some spacing between inputs */
  }

  .contact-form button {
    width: 100%; /* Full width button for better usability */
  }
}

.vision {
  margin-top: 80px; /* Push content below the navbar */
}

/* General Mobile Spacing Fix */
section {
  margin-bottom: 50px; /* Add spacing between all sections to avoid overlap */
}

/* Adjusting Section Titles for Mobile */
h2 {
  font-size: 2.5rem; /* Ensure titles are readable but fit on smaller screens */
  margin-bottom: 20px; /* Consistent spacing below headings */
}

.status-message {
  margin-top: 10px;
  font-size: 1rem;
}

/* Success message styling */
.status-message.success {
  color: #28a745; /* Green color for success */
}

/* Error message styling */
.status-message.error {
  color: #dc3545; /* Red color for error */
}

.why-choose-section {
  padding-top: 120px;
  padding-bottom: 60px;
  max-width: 900px;
  margin: 0 auto;
  background-color: #f9f9f9;
}

.why-choose-section h2 {
  color: #ff6b6b;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 40px;
}

.why-choose-item {
  margin-bottom: 30px;
}

.why-choose-item h3 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 15px;
}

.why-choose-item p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.why-choose-link {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}