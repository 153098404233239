/* Navbar Styling for Mobile */
@media (max-width: 768px) {
    .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
    }
  
    .navbar-logo {
      height: 50px;
    }
  
    .navbar-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 70px; /* Below the navbar */
      right: 0;
      background-color: #fff;
      width: 100%;
      text-align: center;
      padding: 20px 0;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }
  
    .navbar-links a {
      padding: 10px 0;
      color: black;
      text-decoration: none;
      font-size: 1.2rem;
      font-weight: bold;
    }
  
    .navbar-links.active {
      display: flex;
    }
  
    .hamburger {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      gap: 5px;
    }
  
    .bar {
      height: 3px;
      width: 25px;
      background-color: black;
    }
  }
  
  /* Keep navbar as it is for desktop */
  @media (min-width: 769px) {
    .hamburger {
        display: none; /* Hide hamburger on desktop */
      }
    
      .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
      }
    
      .navbar-links {
        display: flex;
        gap: 20px;
        justify-content: flex-end; /* Align the links to the right */
        margin-left: auto; /* Push the links to the right of the navbar */
      }
    
      .navbar-links a {
        text-decoration: none;
        color: black;
        font-size: 1.1rem;
        font-weight: bold;
        white-space: nowrap;
      }
    }
  